import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Event } from 'react-socket-io';
import { NoticeStatus } from 'store/ducks/notice/types';
import { AppState } from 'store/store';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import VEHICLE_TYPES from 'references/vehicle-types';
import {
  applicationsServices,
  daysOffServices,
  ePosServices,
  filesServices,
  schoolServices,
  statusHistoryServices,
  usersServices,
} from 'api/services';
import {
  IApplicationDoc,
  IEventData,
  IResApplicationWithDocs,
  IStatusHistory,
} from 'api/services/applications/types';
import { IResCategory } from 'api/services/categories/types';
import { IResCategoryStatus } from 'api/services/category-statuses/types';
import { IDocType, ISelectedDoc } from 'api/services/docs/types';
import { IResErrorType } from 'api/services/errorTypes/types';
import { IResStatus } from 'api/services/statuses/types';
import { IResUser } from 'api/services/users/types';
import cn from 'classnames';
import Certificate from 'components/Certificate/Certificate';
import { FileUploads } from 'components/FileUploads/FileUploads';
import { MainLoader } from 'components/Loader/Loader';
import Template from 'components/Template/Template';
import TimeLine from 'components/TimeLine/TimeLine';
import {
  isAppealRole,
  isAssistantBranchHead,
  isBranchHead,
  isReceptionRole,
  isSaturdayEditor,
  isSuRole,
  isUserRole,
  isUzakovaRole,
} from 'helpers/checkRoles';
import { Lang, langs } from 'helpers/langs';
import { ShowNotification } from 'helpers/showNotice';
import * as serviceActions from 'store/ducks/services/actions';
import { IResStatusHistory } from 'api/services/status-history/types';
import { ICertificate } from 'api/services/certificates/types';
import { ISelectSchool } from 'pages/CreateApplicationPageByUser/CreateApplicationPageByUser';
import { ReadApplicationByUser } from 'components/ReadApplicationByUser/ReadApplicationByUser';
import { ReactComponent as Payme } from 'assets/images/payme.svg';
import MaskInput from 'components/MaskInput/MaskInput';
import STATUS_TYPES from 'references/status-types';
import Popup from 'components/Popup/Popup';
import { PassModal } from 'components/Popup/components/PassModal/PassModal';
import { VerificationModal } from 'components/Popup/components/VerificationModal/VerificationModal';
import Uzcard from 'assets/images/uzcard-logo.png';
import Humo from 'assets/images/humo-logo.png';
import Confirmation from 'components/Confirmation/Confirmation';
import convertBase64 from 'helpers/convertBase64';
import './UpdateApplicationPage.sass';

export const UpdateApplicationPage: React.FC = () => {
  const [app, setApp] = useState<IResApplicationWithDocs>();
  const [hasError, setHasError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [userPhone, setUserPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [docTypes, setDocTypes] = useState<ISelectedDoc[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<IApplicationDoc[]>([]);
  const [errorDocs, setErrorDocs] = useState<ISelectedDoc[]>([]);
  const [verified, setVerified] = useState(false);
  const [vehicleType, setVehicleType] = useState<IVehicleType>();
  const [applicationCode, setApplicationCode] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [statusHistory, setStatusHistory] = useState<IStatusHistory[]>([]);
  const [activeStatus, setActiveStatus] = useState<IResStatus | any>();
  const [selectedErrorTypes, setSelectedErrorTypes] = useState<number[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<number | null>();
  const [isEventInit, setIsEventInit] = useState(true);
  const [currentCategory, setCurrentCategory] = useState<IResCategory>();
  const [visitDate, setVisitDate] = useState<string>('');
  const [theoryStatus, setTheoryStatus] = useState<IResStatusHistory>();
  const [branchName, setBranchName] = useState('');
  const [applicationLang, setApplicationLang] = useState('');
  const [schools, setSchools] = useState<ISelectSchool[]>([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const [showAddSchool, setShowAddSchool] = useState(false);
  const [newSchool, setNewSchool] = useState('');
  const [currentVehicleType, setCurrentVehicleType] = useState<any>();
  const [showCam, setShowCam] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);

  const [categoryStatuses, setCategoryStatuses] = useState<
    IResCategoryStatus[]
  >([]);
  const [currentErrorTypes, setCurrentErrorTypes] = useState<IResErrorType[]>(
    []
  );
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const selectedCategoryRef = useRef<string>();
  const docTypesRef = useRef<ISelectedDoc[]>();
  const selectedDocsRef = useRef<IApplicationDoc[]>([]);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [visitDateDisabled, setVisitDateDisabled] = useState(false);
  const currentUser = useSelector((state: AppState) => state.users.user);
  const [createdApplicationType, setCreatedApplicationType] = useState('');
  const [initVisitDate, setInitVisitDate] = useState('');
  const [visitTime, setVisitTime] = useState('');
  const [choicedSchool, setChoicedSchool] = useState<{ label: string } | null>(
    null
  );
  const [selectedSchoolId, setSelectedSchoolId] = useState<number>();
  const [selectedBranch, setSelectedBranch] = useState('');
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateApplicationPage
  );
  const [cost, setCost] = useState<number>();
  const [paymeLink, setPaymeLink] = useState('');
  const [exam, setExam] = useState(false);
  const [certificateData, setCertificateData] = useState<ICertificate>();
  const [certificateSerial, setCertificateSerial] = useState('');
  const { categories, docs, regions, errorTypes, branches } = useSelector(
    (state: AppState) => state.services
  );
  const dispatch = useDispatch();
  const [userNotes, setUserNotes] = useState('');
  const [isValid, setIsValid] = useState<boolean | undefined>(false);
  const [selectStatus, setSelectStatus] = useState<IStatusType>();
  const [ePosLoader, setEPosLoader] = useState(false);
  const [expire083, setExpire083] = useState('');
  const [expireMainDoc, setExpireMainDoc] = useState('');
  const [isForeigner, setIsForeigner] = useState('')
  const [forbiden, setForbiden] = useState(true);
  const passModalRef = useRef<any>();
  const webCamModalRef = useRef<any>();
  const confirmationRef = useRef<any>();


  useEffect(() => {
    if (!currentUser) return;
    if (
      currentUser.role === 'SU' ||
      currentUser.role === 'UZAKOVA' ||
      currentUser.role === 'SATURDAY_EDITOR'
    ) {
      setForbiden(false);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    getData();
    scannerSettings();

    return () => {
      setIsEventInit(false);
      if (isReceptionRole(currentUser)) {
        (window as any).disconnect();
      }
    };
  }, []);

  useEffect(() => {
    getApplicationData();
  }, [categories, docs]);

  useEffect(() => {
    visitDateTimer();
  }, [initVisitDate]);

  useEffect(() => {
    getSchools();
  }, [selectedSchoolId]);

  useEffect(() => {
    getBranchName();
  }, [selectedBranch]);

  const getBranchName = () => {
    const branch = branches?.find((i) => i.id === Number(selectedBranch));

    if (branch) {
      setBranchName(branch[`name${lang}`]);
    }
  };

  useEffect(() => {
    setPaymeLink(
      btoa(
        `m=61e93bb8d08179ecc1472d74;ac.application_code=${
          app?.code
        };a=${cost};c=${
          process.env.REACT_APP_FRONT_URL || 'https://cabinet.yim.uz'
        }/applications/${applicationId}`
      )
    );
  }, [cost]);

  useEffect(() => {
    getTheory();
  }, []);

  const scannerSettings = () => {
    const connectScanner = (window as any).connect;
    if (connectScanner && isReceptionRole(currentUser)) {
      connectScanner();
    }
  };

  const visitDateTimer = () => {
    const duration = moment
      .duration(moment(visitDate).diff(moment()))
      .asHours();
    if (duration < 24) setVisitDateDisabled(true);
  };

  const getTheory = async () => {
    try {
      const res = await statusHistoryServices
        .getTheoryById(Number(applicationId))
        .then((res) => res.data);
      setTheoryStatus(res);
    } catch {}
  };

  const getApplicationData = async () => {
    if (!categories.length || !docs.length) return;
    try {
      const res = await applicationsServices
        .getApplicationById(Number(applicationId))
        .then((res) => res.data);
      setApp(res);
      const {
        user,
        category,
        verified,
        docs: appDocs,
        code,
        statusHistory,
        status,
        rejectTypes,
        vehicleType,
        lang,
        date,
        creationType,
        schoolId,
        regionId,
        schoolName,
        branchId,
        pass,
        expire083,
        expireMainDoc,
      } = res;
      const {
        firstName,
        lastName,
        middleName,
        phone,
        pinfl,
        serial,
        number,
        dateOfBirth,
        id,
        notes,
      } = user;

      const categoryDocs = categories.find((i) => i.id === category.id)?.docs;
      setIsForeigner(res.isForeigner === true ? 'true' : res.isForeigner === false ? 'false' : '')
      setVehicleTypes(res.category.vehicleTypes);
      setSelectedCategory(category.id.toString());
      setCurrentCategory(category);
      setFirstName(firstName || '');
      setLastName(lastName || '');
      setSelectedBranch(branchId?.toString() || '');
      setMiddleName(middleName || '');
      setUserPhone(phone || '');
      setPinfl(pinfl || '');
      setNumber(number || '');
      setSerial(serial || '');
      setDateOfBirth(moment(dateOfBirth).format('YYYY-MM-DD') || '');
      setUserId(id);
      setVerified(verified);
      setApplicationCode(code);
      setVehicleType(vehicleType);
      setStatusHistory(statusHistory);
      setCategoryStatuses(category.categoryStatuses);
      setSelectedErrorTypes(rejectTypes?.map((i) => i.id));
      setCurrentVehicleType(vehicleType);
      setCurrentErrorTypes(rejectTypes);
      setActiveStatus(status);
      const time = date?.split(' ')[1];
      setVisitDate(moment(date).format('YYYY-MM-DD') || '');
      setVisitTime(time || '');
      setSelectedRegion(regionId ? regionId : null);
      setInitVisitDate(date || '');
      setExpire083(expire083 || '');
      setExpireMainDoc(expireMainDoc || '');
      setCreatedApplicationType(creationType || '');
      setUserNotes(notes || '');
      if (!schoolId) {
        setShowAddSchool(true);
        setNewSchool(schoolName || '');
        setChoicedSchool({ label: 'Нет в списке' });
      } else {
        setSelectedSchoolId(schoolId);
      }
      setApplicationLang(lang);
      setCost(res?.cost);
      // if (status.name !== 'CREATED' && status.name !== 'NOT_VERIFIED') {
      //   setVisitDateDisabled(true);
      // }
      if (!categoryDocs) return;
      const resultArray = categoryDocs.map((i) => {
        const el = appDocs?.find((ii) => ii.docId === i.id);
        return {
          src: el?.src,
          id: i?.id,
          type: i?.name,
          required: i.required,
        };
      });
      const filteredResultArray = resultArray.filter(
        (i) => i.id
      ) as ISelectedDoc[];
      const findPhotoDoc = filteredResultArray.find((i) => i.type === 'PHOTO');
      if (findPhotoDoc) {
        const findPhotoDocIndex = filteredResultArray.findIndex(
          (i) => i.type === 'PHOTO'
        );
        const newPhotoDoc = {
          id: findPhotoDoc.id,
          src:
            filteredResultArray.find((i) => findPhotoDoc.id === i.id)?.src ||
            '',
          type: findPhotoDoc.type as IDocType,
          required: findPhotoDoc.required || true,
        };
        filteredResultArray.splice(findPhotoDocIndex, 1, newPhotoDoc);
      } else {
        const photoEl = docs.find((i) => i.name === 'PHOTO');
        if (photoEl) {
          filteredResultArray.push({
            id: photoEl.id,
            src: '',
            type: photoEl.name as IDocType,
            required: photoEl.required,
          });
        }
      }
      const selectedResultArray = filteredResultArray.map((i) => ({
        docId: i.id,
        src: i.src || '',
        name: i.type,
        required: !!i.required,
      }));
      if (isUserRole(currentUser)) {
        const docsFindIndx = filteredResultArray.findIndex(
          (i) => i.type === 'PHOTO'
        );
        const selectedFindIndx = selectedResultArray.findIndex(
          (i) => i.name === 'PHOTO'
        );
        selectedResultArray.splice(selectedFindIndx, 1);
        filteredResultArray.splice(docsFindIndx, 1);
      }
      setSelectedDocs(selectedResultArray);
      selectedDocsRef.current = selectedResultArray;
      setDocTypes(filteredResultArray);
      selectedCategoryRef.current = category.id.toString();
      docTypesRef.current = filteredResultArray;
    } catch {
      ShowNotification('Заявка не найдена');
      history('/applications');
    }
  };

  const updateApplication = async (e: React.FormEvent, type?: string) => {
    e.preventDefault();
    if (!type) {
    }

    if (userPhone.length < 12) {
      ShowNotification(i18n.correctPhone);
      setPhoneError(true);
      return;
    }

    if (!userId) {
      ShowNotification(i18n.userNotReceived);
      return;
    }

    if (!selectedCategory || !selectedBranch || !isForeigner.trim()) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }

    if (!expire083.trim() && !isUserRole(currentUser)) {
      setHasError(true);
      ShowNotification('Заполните срок мед. справки');
      return;
    }

    if (!expireMainDoc.trim() && !isUserRole(currentUser)) {
      setHasError(true);
      ShowNotification('Заполните паспорта');
      return;
    }

    if (!vehicleType) {
      setHasError(true);
      ShowNotification(i18n.vehicleType);
      return;
    }

    if (!selectedRegion) {
      setHasError(true);
      ShowNotification('Выберите регион');
      return;
    }
    if (
      selectedDocs.some((i) => i.required && !i.src && i.name !== 'PHOTO') &&
      !selectedErrorTypes.length
    ) {
      const requiredDocs = docTypes.filter(
        (i) => !!i.required && i.type !== 'PHOTO'
      );
      setErrorDocs(requiredDocs.filter((i) => !i.src?.length));
      setHasError(true);
      ShowNotification(i18n.uploadDocuments);
      return;
    }

    const applicationUser = {
      phone: userPhone,
      pinfl: !pinfl.trim() ? null : pinfl,
      serial,
      number,
      firstName,
      lastName,
      middleName,
      date: `${visitDate} ${visitTime} HH:mm`,
      dateOfBirth: dateOfBirth === 'Invalid date' ? '' : dateOfBirth,
      ...(selectedSchoolId ? { schoolId: selectedSchoolId } : {}),
      ...(newSchool.trim() ? { schoolName: newSchool } : {}),
    } as IResUser;

    const applicationData = {
      userId,
      user: applicationUser,
      categoryId: Number(selectedCategory),
      vehicleType: vehicleType,
      docs: selectedDocs,
      regionId: selectedRegion ? Number(selectedRegion) : null,
      branchId: Number(selectedBranch),
      lang: applicationLang as Lang,
      ...(selectedSchoolId ? { schoolId: selectedSchoolId } : {}),
      ...(newSchool.trim() ? { schoolName: newSchool } : {}),
      ...(!!selectedErrorTypes.length
        ? { rejectTypes: selectedErrorTypes }
        : {}),
      expire083,
      expireMainDoc,
      isForeigner: isForeigner === 'true' ? true : isForeigner === 'false' ? false : undefined
    };

    const todayDate = moment(new Date()).format('YYYY-MM-DD');

    if (isUserRole(currentUser)) {
      checkHolyDays();
      if (
        moment(todayDate).isAfter(visitDate) ||
        moment(todayDate).isSame(visitDate)
      ) {
        ShowNotification(i18n.visitDateNotValid);
        setHasError(true);
        return;
      }
      if (activeStatus === 'CREATED') {
        try {
          await applicationsServices.updateVisitDate(Number(applicationId), {
            date: `${visitDate} ${visitTime}`,
          });
          ShowNotification('Дата успешно изменена', NoticeStatus.SUCCESS);
          history('/applications');
        } catch (e: any) {
          if (e?.response?.data?.statusCode === 422) {
            ShowNotification('Выберите другую дату');
          } else {
            ShowNotification(i18n.error);
          }
          setHasError(false);
        }
        return;
      }
      try {
        setLoading(true);
        await applicationsServices
          .updateApplicationByUser(Number(applicationId), {
            ...applicationData,
            date: `${visitDate} ${visitTime}`,
          })
          .then((res) => res.data);
        ShowNotification(i18n.applicationsUpdated, NoticeStatus.INFO);
        history('/applications');
      } catch (e: any) {
        ShowNotification(i18n.error);
        setHasError(false);
      } finally {
        setLoading(false);
      }
      return;
    }

    try {
      if (type === 'cancel') await cancelApplication();
      if (type === 'confirm') await confirmApplication();
      await applicationsServices
        .updateApplication(Number(applicationId), applicationData)
        .then((res) => res.data);
      ShowNotification(i18n.applicationsUpdated, NoticeStatus.INFO);
      history('/applications');
    } catch (e: any) {
      setLoading(false);
      setHasError(false);
      const message = e?.response?.data?.message;
      if (
        message[0] ===
        'vehicleTypeId must be a number conforming to the specified constraints'
      ) {
        ShowNotification(i18n.vehicleType);
        return;
      }
      switch (message) {
        case 'Conflict':
          ShowNotification(i18n.conflict);
          return;
        case 'pinfl':
          ShowNotification(i18n.conflictPinfl);
          return;
        case 'passport':
          ShowNotification(i18n.conflictSerialNumber);
          return;
        case 'phone':
          ShowNotification(i18n.conflictPhone);
          return;
        case 'Not enough files':
          ShowNotification(i18n.uploadDocuments);
          return;
        default:
          return ShowNotification(i18n.error);
      }
    }
  };

  const checkHolyDays = async () => {
    const attrs = {
      from: moment(visitDate).startOf('day').format('YYYY-MM-DDTHH:mm'),
      to: moment(visitDate).endOf('day').format('YYYY-MM-DDTHH:mm'),
    };
    const res = await daysOffServices.getDaysOff(attrs).then((res) => res.data);
    if (res.length) {
      ShowNotification(
        'В выбраный день центр не работает, выберите другой день'
      );
      return;
    }
  };

  const getData = async () => {
    if (!categories.length) dispatch(serviceActions.getCategories());

    if (!docs.length) dispatch(serviceActions.getDocs());

    if (!errorTypes.length) dispatch(serviceActions.getErrorTypes());

    if (!regions.length) dispatch(serviceActions.getRegions());

    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const selectFile = (src: string, id: number) => {
    setErrorDocs((prev) => prev.filter((i) => i.id !== id));
    setSelectedDocs((prev) => {
      const clone = _.cloneDeep(prev);
      const deep = clone.filter((i) => i.docId !== id);
      const findDoc = docTypes.find((i) => i.id === id);
      selectedDocsRef.current = [
        ...deep,
        { src, docId: id, required: !!findDoc?.required },
      ];
      return [...deep, { src, docId: id, required: !!findDoc?.required }];
    });
  };

  const selectCategory = (categoryId: string) => {
    setSelectedCategory(categoryId);
    const selectedCategory = categories.find(
      (i) => i.id === Number(categoryId)
    );
    if (!selectedCategory) return;
    const resultArray = selectedCategory.docs.map((i) => {
      const el = selectedDocs.find((ii) => i.id === ii.docId);
      return {
        src: el?.src,
        id: el?.docId,
        type: i.name,
        required: el?.required,
      };
    });
    const filteredResultArray = resultArray.filter(
      (i) => !!i.src
    ) as ISelectedDoc[];
    setSelectedDocs(
      filteredResultArray.map((i) => ({
        docId: i.id,
        src: i.src || '',
        required: !!i.required,
      }))
    );
    setDocTypes(
      selectedCategory.docs.map((i) => {
        const findFile = filteredResultArray.find((ii) => ii.id === i.id);
        return {
          type: i.name as IDocType,
          id: i.id,
          required: i.required,
          src: findFile?.src || '',
        };
      })
    );
    setVehicleTypes(selectedCategory.vehicleTypes);
  };

  const selectErrorTypes = (id: number) => {
    if (selectedErrorTypes.some((i) => i === id)) {
      let filterArr = selectedErrorTypes.filter((i) => i !== id);
      setSelectedErrorTypes([...filterArr]);
      return;
    }
    setSelectedErrorTypes((prev) => [...prev, id]);
  };

  const cancelApplication = async () => {
    if (!applicationId) return;
    setCancelLoading(true);
    try {
      await applicationsServices.rejectApplication(applicationId);
      ShowNotification(i18n.applicationRejected, NoticeStatus.INFO);
      history('/applications');
    } catch {
      ShowNotification(i18n.errorTryLater);
      setCancelLoading(false);
    }
  };
  const subscribeApplicationData = async (data: IEventData) => {
    const categoryRefId = selectedCategoryRef.current;
    const docsRef = docTypesRef.current;
    if (!categoryRefId || !docsRef) {
      // ShowNotification(i18n.choiceCategory);
      return;
    }
    const {
      firstName,
      middleName,
      documentName,
      scan,
      serial,
      number,
      lastName,
      dateOfBirth,
      pinfl,
      expireMainDoc,
    } = data;
    setFirstName(firstName);
    if (middleName) {
      setMiddleName(middleName);
    }
    setNumber(number);
    setLastName(lastName);
    setDateOfBirth(moment(dateOfBirth).format('YYYY-MM-DD'));
    setPinfl(pinfl);
    setSerial(serial);
    setExpireMainDoc(expireMainDoc);
    const findDoc = docsRef?.find((i) => i.type === documentName);
    if (!findDoc) return;
    const newDoc: ISelectedDoc = {
      id: findDoc?.id,
      src: scan,
      type: findDoc?.type,
      required: findDoc.required || true,
    };
    const newSelectedDoc: IApplicationDoc = {
      docId: findDoc?.id,
      src: scan,
      name: findDoc?.type,
      required: findDoc.required || true,
    };
    const docsFindIndx = docsRef.findIndex((i) => i.type === 'PHOTO');
    const newArrSplice = docsRef;
    newArrSplice.splice(docsFindIndx, 1, newDoc);
    const selectedResult = selectedDocsRef.current.filter(
      (i) => i.name !== documentName && i.docId !== findDoc.id
    );
    selectedDocsRef.current = [...selectedResult, newSelectedDoc];
    setSelectedDocs([...selectedResult, newSelectedDoc]);
    setDocTypes(newArrSplice);
    docTypesRef.current = newArrSplice;
  };

  const subscribeApplicationDataByScanner = (data: IEventData) => {
    const categoryRefId = selectedCategoryRef.current;
    const docsRef = docTypesRef.current;
    if (!categoryRefId || !docsRef) {
      ShowNotification(i18n.choiceCategory);
      return;
    }
    const { documentName, scan } = data;
    const findDoc = docsRef?.find((i) => i.type === documentName);
    if (!findDoc) return;
    const newDoc: ISelectedDoc = {
      id: findDoc?.id,
      src: scan,
      type: findDoc?.type,
      required: findDoc.required,
    };
    const newSelectedDoc: IApplicationDoc = {
      docId: findDoc?.id,
      src: scan,
      name: findDoc?.type,
      required: findDoc.required || true,
    };
    const selectedResult = selectedDocsRef.current.filter(
      (i) => i.docId !== newSelectedDoc.docId
    );
    const docsFindIndx = docsRef.findIndex((i) => i.id === newDoc.id);
    const newArrSplice = docsRef;
    newArrSplice.splice(docsFindIndx, 1, newDoc);
    selectedDocsRef.current = [...selectedResult, newSelectedDoc];
    setSelectedDocs([...selectedResult, newSelectedDoc]);
    setDocTypes(newArrSplice);
    docTypesRef.current = newArrSplice;
  };

  const verificationApplication = async (photo: string) => {
    if (!applicationId) return;
    const sendPhoto = photo.replace('data:image/jpeg;base64,', '');
    try {
      const res = await applicationsServices
        .verificationApplication(applicationId, { photo: sendPhoto })
        .then((res) => res.data);
      setVerified(res);
      if (!res) {
        ShowNotification('Ошибка верификации, попробуйте еще раз');
        return;
      }
      ShowNotification('Заявка успешно верифицированна', NoticeStatus.SUCCESS);
      webCamModalRef.current.onHide();
      passModalRef.current.onShow();
      history('/applications');
    } catch {
      ShowNotification('Ошибка верификации, попробуйте еще раз');
    }
  };

  const confirmApplication = async () => {
    if (!applicationId) return;
    setConfirmLoading(true);
    try {
      await applicationsServices.verifyApplication(applicationId);
      ShowNotification(i18n.confirmedApplication, NoticeStatus.SUCCESS);
      history('/applications');
    } catch {
      ShowNotification(i18n.errorTryLater);
      setConfirmLoading(false);
    }
  };

  const confirmPass = async (pass: string) => {
    if (pass.length < 9) return;

    try {
      await applicationsServices.passBinding({
        applicationId: Number(applicationId),
        pass,
      });
      ShowNotification(i18n.passIssued, NoticeStatus.INFO);
      getApplicationData();
      passModalRef.current.onHide();
    } catch (e: any) {
      if (e?.response?.data?.message === 'application_pass_conflict') {
        ShowNotification(
          'Данный QR-код уже присвоен другому кандидату. Используйте следующий QR-код.'
        );
      } else {
        
        ShowNotification(i18n.errorTryLater);
      }
    }
  };

  const checkStatus = (status?: string) => {
    switch (status) {
      case 'T_PAYMENT':
        return (
          <div className='update-application-page__methods'>
            <a
              href={`https://checkout.paycom.uz/${paymeLink}`}
              className='update-application-page__pay update-application-page__pay--payme'
            >
              <Payme />
            </a>
            <button
              onClick={() => getEPosId()}
              className='update-application-page__pay'
              disabled={ePosLoader}
            >
              <img src={Humo} />
              <img src={Uzcard} />
            </button>
          </div>
        );
      case 'P_PAYMENT':
        return (
          <div className='update-application-page__methods'>
            <a
              href={`https://checkout.paycom.uz/${paymeLink}`}
              className='update-application-page__pay update-application-page__pay--payme'
            >
              <Payme />
            </a>
            <button
              className='update-application-page__pay'
              onClick={() => getEPosId()}
              disabled={ePosLoader}
            >
              <img src={Humo} />
              <img src={Uzcard} />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const request = _.debounce(async (value) => {
    const params = {
      skip: page * take,
      take,
      search: value,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
  }, 700);

  const debouceRequest = useCallback((value: any) => request(value), []);

  const findSchool = (school: string) => {
    if (!school) return;
    debouceRequest(school);
  };
  const selectSchool = (target: any) => {
    const value = target.textContent;
    setChoicedSchool({ label: value });

    if (value === i18n.notInList && window && !isUserRole(currentUser)) {
      window.open(`${window.location.origin}/schools/create`, '_blank');
      return;
    }
    setShowAddSchool(false);
    setNewSchool('');
    const selectedSchool = schools.find((i) => i.title === value);
    setSelectedSchoolId(selectedSchool?.id);
  };

  const resetData = () => {
    if (!selectedSchoolId && !showAddSchool) getSchools();
  };

  const getSchools = async () => {
    const params = {
      skip: page * take,
      take,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
    if (!selectedSchoolId || isUserRole(currentUser)) return;
    const findSchool = await schoolServices
      .getSchoolById(Number(selectedSchoolId))
      .then((res) => res.data);
    if (findSchool) {
      setChoicedSchool({ ...findSchool, label: findSchool.title });
    }
  };

  const updateStatus = async () => {
    if (!app?.id) return;
    if (!selectStatus) return;
    const params = {
      status: selectStatus,
    };
    try {
      await applicationsServices.updateApplicationStatus(app.id, params);
    } catch (e: any) {}
  };

  const openCertificate = (
    data: ICertificate,
    serial: string,
    isValid?: boolean
  ) => {
    setCertificateSerial(serial);
    setExam(false);
    setCertificateData(data);
    setIsValid(isValid);
    if (isValid) {
      setTimeout(() => {
        window.print();
      }, 0);
    } else {
      return;
    }
  };

  const closeModal = () => {
    passModalRef.current.onHide();
  };

  const changeExam = (value: boolean) => {
    setExam(value);
  };

  const getScreen = (photo: string) => {
    if (isBranchHead(currentUser) || isAssistantBranchHead(currentUser)) {
      onDrop(photo);
    } else {
      verificationApplication(photo);
    }
  };

  const onDrop = async (photo: any) => {
    const formData = new FormData();
    const file = convertBase64(photo, `${new Date().toLocaleString()}.jpeg`);
    formData.append('file', file);
    try {
      const res = await filesServices
        .uploadFile(formData)
        .then((res) => res.data);
      forceVerify(res);
    } catch (e: any) {
      ShowNotification(i18n.fileSize);
    } finally {
      setLoading(false);
    }
  };

  const forceVerify = async (photo: string) => {
    if (!applicationId) return;
    const requestData = {
      photoUrl: photo,
    };
    try {
      await applicationsServices.forceVerify(applicationId, requestData);
      setVerified(true);
      ShowNotification('Успешно верифицированно', NoticeStatus.SUCCESS);
      webCamModalRef.current.onHide();
    } catch {
      ShowNotification('Ошибка попробуйте позже');
    }
  };

  const getEPosId = async () => {
    if (!lang || !applicationId) return;
    const params = {
      lang: lang,
      applicationId: Number(applicationId),
      redirectUrl: `https://cabinet.yim.uz/applications/${applicationId}`,
      transactionType: 'EPOS',
    };
    try {
      setEPosLoader(true);
      const res = await ePosServices
        .createEposTransaction(params)
        .then((res) => res.data);
      window.location.href = res;
    } catch (e: any) {
      ShowNotification('Ошибка попробуйте позже');
    } finally {
      setEPosLoader(false);
    }
  };

  const handleBlockUser = async () => {
    if (!app?.user) return;

    try {
      await usersServices.blackListUser(app.user.id, {
        blackList: !app.user.blackList,
      });
      getApplicationData();
    } catch {}
  };

  const addNote = async () => {
    if (!app?.user) return;

    try {
      await usersServices.addNoteUser(app.user.id, {
        notes: userNotes,
      });
      ShowNotification('Заметка сохранена', NoticeStatus.SUCCESS);
      getApplicationData();
    } catch {}
  };

  return (
    <Template>
      <Popup ref={passModalRef}>
        <PassModal onHide={closeModal} confirm={confirmPass} />;
      </Popup>
      <Popup
        ref={webCamModalRef}
      >
        <VerificationModal
          onHide={() => webCamModalRef.current.onHide()}
          getScreen={getScreen}
        />
      </Popup>
      <div
        className={cn('update-application-page', {
          'update-application-page--exam': exam === true,
        })}
        id='update-application-page'
      >
        {isEventInit && (
          <>
            <Event
              handler={subscribeApplicationDataByScanner}
              event={`scan_${currentUser.id}`}
            />
            <Event
              handler={subscribeApplicationData}
              event={`reader_${currentUser.id}`}
            />
          </>
        )}
        <div className='scanner-loader'>
          <CircularProgress className='scanner-loader-spinner' />
        </div>
        <Grid
          container
          alignItems='center'
          className='update-application-page__header'
        >
          <Grid
            item
            container
            justifyContent='space-between'
            alignItems='center'
            className='update-application-page__header-top'
            flex={1}
          >
            <Grid item className='update-application-page__title'>
              <Typography variant='h3'>
                {isUserRole(currentUser)
                  ? i18n.application
                  : i18n.updateApplication}
                {applicationCode
                  ? `${!!applicationCode && ' - ' + applicationCode}`
                  : ''}
              </Typography>
              {!isUserRole(currentUser) && (
                <span
                  className={cn('update-application-page__title-sub', {
                    'update-application-page__title-sub--verified': verified,
                  })}
                >
                  {!verified ? 'Не верифицированная' : 'Верифицированная'}
                </span>
              )}
            </Grid>
            <Grid item container flex={1} justifyContent='flex-end' spacing={1}>
              {!verified && isReceptionRole(currentUser) && (
                <Grid item>
                  <Button
                    color='info'
                    variant='contained'
                    onClick={() => webCamModalRef.current.onShow()}
                  >
                    Верифицировать заявку
                  </Button>
                </Grid>
              )}
              {((!verified && isSuRole(currentUser)) ||
                (!verified && isBranchHead(currentUser)) ||
                (!verified && isAssistantBranchHead(currentUser))) && (
                <Grid item>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => webCamModalRef.current.onShow()}
                  >
                    Верифицировать
                  </Button>
                </Grid>
              )}
              {!isUserRole(currentUser) ? (
                <Grid item alignItems='center' justifyContent='flex-end'>
                  {activeStatus !== 'NOT_VERIFIED' &&
                    activeStatus !== 'CREATED' && (
                      <Button
                        color='success'
                        variant='contained'
                        onClick={() => passModalRef.current.onShow()}
                      >
                        {i18n.passIssue}
                      </Button>
                    )}
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant='contained'
                    color='success'
                    disabled={
                      activeStatus !== 'NOT_VERIFIED' && visitDateDisabled
                    }
                    onClick={updateApplication}
                  >
                    {i18n.save}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <div className='update-application-page__actions'>
            <Typography
              className={cn('update-application-page__status-info', {
                'update-application-page__status-info--red':
                  activeStatus === 'T_FAILED' || activeStatus === 'P_FAILED',
                'update-application-page__status-info--success':
                  activeStatus === 'COMPLETED',
              })}
              variant='h4'
            >
              {STATUS_TYPES.find((ii) => ii.value === activeStatus)?.label}{' '}
              {app?.user?.blackList ? '(Заблокирован)' : null}
            </Typography>
            <div>
              {!isUserRole(currentUser) && (
                <Link to={`/applications/${applicationId}/official-report`}>
                  <Button
                    color='info'
                    className='update-application-page__official-report'
                    variant='contained'
                  >
                    Запросить изменения
                  </Button>
                </Link>
              )}
              {!isUserRole(currentUser) && (
                <Button
                  color={!app?.user?.blackList ? 'secondary' : 'success'}
                  variant='contained'
                  onClick={() => confirmationRef.current.onShow()}
                >
                  {!app?.user?.blackList ? 'В черный список' : 'Разблокировать'}
                </Button>
              )}
            </div>
          </div>

          {!!setCategoryStatuses.length && activeStatus && (
            <>
              <TimeLine theory={theoryStatus} app={app} onExam={changeExam} />
              {activeStatus !== 'CREATED' && (
                <Link
                  to={`/applications/${applicationId}/status-history`}
                  className='update-application-page__more'
                >
                  <Button size='small' color='success' variant='contained'>
                    {i18n.more}
                  </Button>
                </Link>
              )}
              {(isSuRole(currentUser) || isAppealRole(currentUser)) &&
                (activeStatus === 'T_FAILED' ||
                  activeStatus === 'P_FAILED') && (
                  <Link to={`/applications/${applicationId}/appeal`}>
                    <Button
                      className='update-application-page__appeal'
                      size='small'
                      color='error'
                      variant='contained'
                    >
                      Апелляция
                    </Button>
                  </Link>
                )}
            </>
          )}
        </Grid>
        <form
          className='update-application-page__form'
          onSubmit={updateApplication}
        >
          <Grid
            className='row update-application-page__modal-container'
            container
            spacing={2}
            alignItems='flex-start'
          >
            <Grid md={6} sm={8} xs={12} container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4'>{i18n.appData}</Typography>
              </Grid>
              {!isUserRole(currentUser) ? (
                <>
                  <Grid item xs={12}>
                    {!!categories.length && (
                      <FormControl fullWidth size='small'>
                        <InputLabel>{i18n.category}</InputLabel>
                        <Select
                          value={selectedCategory}
                          disabled={
                            activeStatus !== 'CREATED' &&
                            activeStatus !== 'NOT_VERIFIED'
                          }
                          label={i18n.category}
                          error={!selectedCategory && hasError}
                          onChange={(e) => selectCategory(e.target.value)}
                        >
                          {categories.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                              {i[`title${lang}`]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size='small'>
                      <InputLabel>{i18n.transport}</InputLabel>
                      <Select
                        value={vehicleType || ''}
                        label={i18n.transport}
                        error={!vehicleType && hasError}
                        onChange={(e) =>
                          setVehicleType(e.target.value as IVehicleType)
                        }
                      >
                        {VEHICLE_TYPES.map((i) => (
                          <MenuItem key={i.value} value={i.value}>
                            {i.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {isSuRole(currentUser) && (
                    <Grid item xs={12}>
                      <FormControl fullWidth size='small'>
                        <InputLabel>Статус</InputLabel>
                        <Select
                          value={selectStatus}
                          disabled={!isSuRole(currentUser)}
                          label='Статус'
                          onChange={(e) =>
                            setSelectStatus(e.target.value as IStatusType)
                          }
                        >
                          {STATUS_TYPES.map((i) => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant='contained'
                        color='success'
                        className='update-application-page__button--status'
                        onClick={() => updateStatus()}
                      >
                        Изменить статус
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth size='small'>
                      <InputLabel error={!isForeigner && hasError}>
                        {i18n.isForeigner}
                      </InputLabel>
                      <Select
                        label={i18n.isForeigner}
                        value={isForeigner}
                        error={!isForeigner && hasError}
                        onChange={(e) => setIsForeigner(e.target.value)}
                      >
                        <MenuItem value={'false'}>
                          {i18n.resident}
                        </MenuItem>
                        <MenuItem value={'true'}>
                          {i18n.noResident}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {regions && !!regions.length && (
                      <FormControl fullWidth size='small'>
                        <InputLabel>{i18n.residencePlace}</InputLabel>
                        <Select
                          value={selectedRegion || ''}
                          label={i18n.residencePlace}
                          error={!selectedRegion && hasError}
                          onChange={(e) =>
                            setSelectedRegion(e.target.value as number)
                          }
                        >
                          {regions.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                              {i[`title${lang}`]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {createdApplicationType === 'USER' && (
                    <Grid item xs={12}>
                      <TextField
                        value={visitDate}
                        onChange={(e) => setVisitDate(e.target.value)}
                        label={i18n.visitDate}
                        autoComplete='off'
                        disabled
                        variant='outlined'
                        size='small'
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      value={choicedSchool}
                      disablePortal
                      className={cn('create-application-page-by-user__select', {
                        'create-application-page-by-user__select--error':
                          hasError && !selectedSchoolId,
                      })}
                      onChange={(e) => selectSchool(e.target)}
                      size='small'
                      options={[...schools, { label: i18n.notInList }]}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      disabled={
                        activeStatus !== 'CREATED' &&
                        activeStatus !== 'NOT_VERIFIED' &&
                        !isSuRole(currentUser) &&
                        !isUzakovaRole(currentUser) &&
                        choicedSchool?.label !== 'Нет в списке'
                      }
                      renderInput={(params) => (
                        <TextField
                          error={hasError && !selectedSchoolId}
                          {...params}
                          onBlur={resetData}
                          label={i18n.choiceSchool}
                          onChange={(e) => findSchool(e.target.value)}
                        />
                      )}
                    />
                  </Grid>
                  {showAddSchool && (
                    <Grid item xs={12}>
                      <TextField
                        value={newSchool}
                        onChange={(e) => setNewSchool(e.target.value)}
                        label={i18n.enterSchoolName}
                        size='small'
                        autoComplete='off'
                        error={showAddSchool && hasError && !newSchool.trim()}
                      />
                    </Grid>
                  )}
                  {!!branchName.length && (
                    <Grid item xs={12}>
                      <TextField
                        value={branchName}
                        size='small'
                        disabled={!isSuRole(currentUser)}
                        label='Филиал'
                        autoComplete='off'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {!!langs.length && (
                      <FormControl fullWidth size='small'>
                        <InputLabel>{i18n.selectLanguage}</InputLabel>
                        <Select
                          value={applicationLang}
                          label={i18n.selectLanguage}
                          error={!applicationLang.trim() && hasError}
                          onChange={(e) => setApplicationLang(e.target.value)}
                        >
                          {langs.map((i) => (
                            <MenuItem key={i.label} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={expireMainDoc}
                      onChange={(e) => setExpireMainDoc(e.target.value)}
                      label={'Срок паспорта'}
                      autoComplete='off'
                      inputProps={{ maxLength: 255 }}
                      variant='outlined'
                      size='small'
                      type='date'
                      InputLabelProps={{ shrink: true }}
                      error={!expireMainDoc.trim() && hasError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={expire083}
                      onChange={(e) => setExpire083(e.target.value)}
                      label={'Срок мед. справки'}
                      autoComplete='off'
                      inputProps={{ maxLength: 255 }}
                      variant='outlined'
                      size='small'
                      type='date'
                      InputLabelProps={{ shrink: true }}
                      error={!expire083.trim() && hasError}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={'update-application-page__user-data'}
                  >
                    <Grid item xs={12}>
                      <Typography variant='h4'>{i18n.userData}</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <MaskInput
                          phone={userPhone}
                          setPhone={setUserPhone}
                          className={cn(
                            'create-application-page__search-input',
                            'create-application-page__phone create-application-page__field',
                            {
                              'create-application-page__phone--error':
                                hasError && userPhone.length < 12,
                            }
                          )}
                        />
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            autoComplete='off'
                            value={serial}
                            // disabled={forbiden && verified}
                            onChange={(e) =>
                              setSerial(e.target.value.toUpperCase())
                            }
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            size='small'
                            label={i18n.passportSeries}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            autoComplete='off'
                            value={number}
                            type='text'
                            // disabled={forbiden && verified}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setNumber(e.target.value)}
                            variant='outlined'
                            size='small'
                            label={i18n.passportNumber}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={pinfl}
                          // disabled={forbiden && verified}
                          onChange={(e) => setPinfl(e.target.value)}
                          label={i18n.pinfl}
                          InputLabelProps={{ shrink: true }}
                          autoComplete='off'
                          variant='outlined'
                          size='small'
                          inputProps={{ maxLength: 14 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete='off'
                        value={firstName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant='outlined'
                        size='small'
                        label={i18n.firstName}
                        disabled={verified && forbiden}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete='off'
                        value={lastName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setLastName(e.target.value)}
                        variant='outlined'
                        size='small'
                        label={i18n.lastName}
                        disabled={verified && forbiden}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete='off'
                        value={middleName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setMiddleName(e.target.value)}
                        variant='outlined'
                        size='small'
                        label={i18n.middleName}
                        disabled={verified && forbiden}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        label={i18n.dateOfBirth}
                        autoComplete='off'
                        variant='outlined'
                        size='small'
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        disabled={verified && forbiden}
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => setVerified(e.target.checked)}
                            checked={verified}
                          />
                        }
                        label={i18n.verification}
                      />
                    </Grid> */}
                  </Grid>
                  {(activeStatus === 'CREATED' ||
                    activeStatus === 'NOT_VERIFIED') && (
                    <Grid item xs={12}>
                      <div className='update-application-page__tags'>
                        {errorTypes.map((i) => (
                          <div
                            className={cn('update-application-page__tag', {
                              'update-application-page__tag--selected':
                                selectedErrorTypes.some((ii) => i.id === ii),
                            })}
                            key={i.id}
                            onClick={() => selectErrorTypes(i.id)}
                          >
                            <Typography variant='h5'>
                              {i[`title${lang}`]}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  )}
                  {(activeStatus === 'CREATED' ||
                    activeStatus === 'NOT_VERIFIED') && (
                    <Grid item xs={6}>
                      <Button
                        variant='contained'
                        className='update-application-page__button'
                        color='warning'
                        style={{ background: '#ff0000' }}
                        disabled={
                          activeStatus === 'NOT_VERIFIED' ||
                          !selectedErrorTypes.length
                        }
                        onClick={(e) => updateApplication(e, 'cancel')}
                      >
                        {cancelLoading ? (
                          <MainLoader />
                        ) : activeStatus === 'CREATED' ? (
                          i18n.cancelApp
                        ) : (
                          i18n.applicationRejected
                        )}
                      </Button>
                    </Grid>
                  )}
                  {(activeStatus === 'CREATED' ||
                    activeStatus === 'NOT_VERIFIED') && (
                    <Grid item xs={6}>
                      <Button
                        onClick={(e) => updateApplication(e, 'confirm')}
                        variant='contained'
                        disabled={confirmLoading}
                        className='update-application-page__button update-application-page__button--confirm'
                        color='success'
                      >
                        {confirmLoading ? (
                          <MainLoader />
                        ) : (
                          i18n.confirmApplication
                        )}
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    spacing={2}
                    className='update-application-page__button-wrap'
                  >
                    <Grid item xs={6}>
                      <Link
                        className='update-application-page__button'
                        to='/applications'
                      >
                        <Button
                          variant='contained'
                          color='inherit'
                          className='update-application-page__button'
                          size='large'
                        >
                          {i18n.back}
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant='contained'
                        color='success'
                        className='update-application-page__button'
                        type='submit'
                        disabled={
                          loading || (app?.verified && !isSuRole(currentUser))
                        }
                        size='large'
                      >
                        {loading ? <MainLoader /> : i18n.save}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className='update-appliction-page__certificates'
                    >
                      {app?.certificates
                        ?.filter((ii) => ii.isValid)
                        ?.map((iii) => (
                          <Button
                            key={iii.id}
                            variant='contained'
                            color='success'
                            className='update-application-page__button-certificate'
                            type='button'
                            disabled={loading}
                            onClick={() =>
                              openCertificate(
                                iii,
                                `${iii?.serial}${iii?.id
                                  .toString()
                                  .padStart(14, '0')}`,
                                iii.isValid
                              )
                            }
                            size='large'
                          >
                            {loading ? (
                              <MainLoader />
                            ) : (
                              `${i18n.printCertificate} ${iii.vehicleType}`
                            )}
                          </Button>
                        ))}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <ReadApplicationByUser
                  firstName={firstName}
                  lastName={lastName}
                  middleName={middleName}
                  userPhone={userPhone}
                  currentCategory={currentCategory}
                  currentErrorTypes={currentErrorTypes}
                  activeStatus={activeStatus}
                  vehicleType={currentVehicleType || ''}
                  branchName={branchName}
                  visitDateDisabled={visitDateDisabled}
                  checkStatus={checkStatus}
                  visitDate={visitDate}
                  setVisitDate={setVisitDate}
                  hasError={hasError}
                  creationType={createdApplicationType as ICreationType}
                  visitTime={visitTime}
                  app={app as any}
                  setVisitTime={setVisitTime}
                />
              )}
            </Grid>
            {(!isUserRole(currentUser) ||
              (activeStatus === 'NOT_VERIFIED' && isUserRole(currentUser))) && (
              <Grid item xs={12} sm={4} md={6}>
                <Grid
                  item
                  container
                  style={{ height: '100%' }}
                  spacing={2}
                  alignItems='flex-start'
                  xs={12}
                >
                  <Grid item className='update-application-page__ticket'>
                    <Typography variant='h4'>{i18n.docsList}</Typography>
                    <Link to={`/applications/${applicationId}/ticket`}>
                      <Button size='small' color='success' variant='contained'>
                        Подать рапорт
                      </Button>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    height='100%'
                    alignItems='stretch'
                  >
                    {docTypes
                      ?.sort((a, b) => a.type.localeCompare(b.type))
                      .map((i) => (
                        <React.Fragment key={i.id}>
                          <Grid item md={6} sm={12} xs={12}>
                            <FileUploads
                              doc={i}
                              selectFile={selectFile}
                              errorDoc={
                                errorDocs.find((ii) => ii.id === i.id)?.type
                              }
                              key={i.id}
                              hideUpload={
                                isUserRole(currentUser) &&
                                activeStatus !== 'NOT_VERIFIED' &&
                                activeStatus !== 'CREATED'
                              }
                              disabled={
                                app?.verified && currentUser?.role !== 'SU'
                              }
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    alignItems={'flex-end'}
                  >
                    <Grid item xs={6}>
                      <textarea
                        className='update-application-page__textarea'
                        // minRows={6}
                        placeholder='Укажите причину добавления кандидата в черный список'
                        value={userNotes}
                        onChange={(e) => setUserNotes(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant='contained'
                        color='success'
                        onClick={addNote}
                      >
                        {app?.user?.notes?.trim() ? 'Изменить' : 'Добавить'}
                        заметку
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
      {app?.certificates &&
        app?.certificates?.length > 0 &&
        exam === false &&
        isValid && (
          <Certificate
            className='payment-page__certificate'
            data={certificateData}
            application={app}
            serial={certificateSerial}
          />
        )}
      <Confirmation
        submit={handleBlockUser}
        title={
          !app?.user?.blackList
            ? 'Вы действительно хотите добавить кандидата в черный список?'
            : 'Убрать кандидата из черного списка?'
        }
        ref={confirmationRef}
      />
    </Template>
  );
};
