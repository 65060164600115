import { Link } from "react-router-dom"
import VEHICLE_TYPES from "references/vehicle-types"
import { Button, Typography } from "@mui/material"
import Template from "components/Template/Template"
import './VehicleTypesPage.sass'


const VehicleTypesPage: React.FC = () => {
  return (
    <Template>
      <div className="vehicle-types-page">
        <Typography variant='h3'> Типы транспортных средств</Typography>
        {VEHICLE_TYPES.map((i) => (
          <Link to={`/vehicle-types/${i.value}/practice-exercise`} key={i.value} className="vehicle-types-page__link">
            <Button variant='contained' color="info">{i.value}</Button>
          </Link>
        ))}
      </div>
    </Template>
  )
}


export default VehicleTypesPage