import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import moment from 'moment';
import { statusHistoryServices } from 'api/services';
import { IResStatusHistory } from 'api/services/status-history/types';
import STATUS_TYPES from 'references/status-types';
import Template from 'components/Template/Template';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import './SummaryPage.sass';
import { isReportRole, isSaturdayEditor, isSuRole, isUzakovaRole } from 'helpers/checkRoles';

const initialFilters = {
  value: '',
  oldStatuses: undefined,
  newStatuses: undefined,
  createdFrom: '',
  createdTo: '',
  attemptCountType: undefined,
  branchId: undefined,
};

const SummaryPage: React.FC = () => {
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [value, setValue] = useState('');
  const [createdFrom, setCreatedFrom] = useState('');
  const [createdTo, setCreatedTo] = useState('');
  const [oldStatuses, setOldStatuses] = useState<IStatusType[]>([]);
  const [newStatuses, setNewStatuses] = useState<IStatusType[]>([]);
  const [data, setData] = useState<IResStatusHistory[]>();
  const [attemptCountType, setAttemptCountType] = useState<IAttemptCountType>();
  const [branchId, setBranchId] = useState<any>();
  const [filters, setFilters] = useState<{
    value: string;
    oldStatuses?: IStatusType[];
    newStatuses?: IStatusType[];
    createdFrom: string;
    createdTo: string;
    attemptCountType?: IAttemptCountType;
    branchId?: number;
  }>(initialFilters);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.SummaryPage
  );
  const { user } = useSelector((state: AppState) => state.users)
  const { branches } = useSelector((state: AppState) => state.services);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!user?.branchId) return
    setBranchId(user?.branchId)
    setFilters({
      value,
      oldStatuses,
      newStatuses,
      createdFrom,
      createdTo,
      attemptCountType,
      branchId: Number(user.branchId),
    });
  }, [user?.branchId])

  useEffect(() => {
    if(!branchId) return
    getData()
  }, [branchId, filters, page, take])

  useEffect(() => {
    if(!user) return
    if(isSuRole(user) || (isReportRole(user) && !user?.branchId) || (isUzakovaRole(user) && !user?.branchId) || (isSaturdayEditor(user) && !user?.branchId)) {
      getData()
    }
  }, [branchId, filters, take, user?.id, page])

  useEffect(() => {
    getDicts();
  }, []);

  const getDicts = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    };
    const res = await statusHistoryServices
      .getStatusHistory(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      value,
      oldStatuses,
      newStatuses,
      createdFrom,
      createdTo,
      attemptCountType,
      branchId,
    });
  };

  const clearHandler = () => {
    setValue('');
    setCreatedTo('');
    setCreatedFrom('');
    setOldStatuses([]);
    setNewStatuses([]);
    setBranchId(undefined);
    setFilters(initialFilters);
    setPage(0);
    getData();
  };

  return (
    <Template>
      <div className='summary-page'>
        <Grid container spacing={2} className='summary-page__inner'>
          <Grid item>
            <Typography className='summary-page__title' variant='h3'>
              {i18n.summary}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={search} className='summary-page__form'>
          <Grid container spacing={2}>
            {!!branches.length && !user?.branchId && (
              <Grid item md={3} xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>{i18n.branches}</InputLabel>
                  <Select
                    label={i18n.branches}
                    value={branchId}
                    onChange={(e) => setBranchId(e.target.value)}
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={3} xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.oldStatus}</InputLabel>
                <Select
                  label={i18n.oldStatus}
                  multiple
                  value={oldStatuses}
                  onChange={(e) => setOldStatuses(e.target.value as IStatusType[])}
                >
                  {STATUS_TYPES?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.newStatus}</InputLabel>
                <Select
                  label={i18n.newStatus}
                  multiple
                  value={newStatuses}
                  onChange={(e) => setNewStatuses(e.target.value as IStatusType[])}
                >
                  {STATUS_TYPES?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={createdFrom}
                onChange={(e) => setCreatedFrom(e.target.value)}
                label={i18n.from}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={createdTo}
                onChange={(e) => setCreatedTo(e.target.value)}
                label={i18n.to}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='value'>
                  {i18n.result}
                </InputLabel>
                <Select
                  labelId='value'
                  label='value'
                  onChange={(e) => setValue(e.target.value as string)}
                  value={value || ''}
                  size='small'
                >
                  <MenuItem value='true'>{i18n.true}</MenuItem>
                  <MenuItem value='false'>{i18n.false}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='value'>
                  {i18n.attempts}
                </InputLabel>
                <Select
                  labelId='value'
                  label='value'
                  onChange={(e) =>
                    setAttemptCountType(e.target.value as IAttemptCountType)
                  }
                  value={attemptCountType || ''}
                  size='small'
                >
                  <MenuItem value='FIRST'>{i18n.firstAttempt}</MenuItem>
                  <MenuItem value='MORE_THAN_ONE'>{i18n.retake}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='summary-page__button'
              >
                {i18n.search}
              </Button>
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='summary-page__button'
              >
                {i18n.clear}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table className='summary-page__table'>
            <TableHead>
              <TableRow>
                <TableCell className='summary-page__td'>№</TableCell>
                <TableCell className='summary-page__td'>{i18n.fio}</TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.passport}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.appCode}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.vehicleTypes}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.branch}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.oldStatus}
                </TableCell>
                <TableCell className='summary-page__td'>{i18n.newStatus}</TableCell>
                <TableCell className='summary-page__td'>{i18n.attempt}</TableCell>
                <TableCell className='summary-page__td'>{i18n.phone}</TableCell>

                <TableCell className='summary-page__td'>
                  {i18n.result}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.createdAt}
                </TableCell>
                <TableCell className='summary-page__td'>
                  {i18n.createdAt}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell className='summary-page__td'>
                    {index + 1}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    <Link
                      className='summary-page__link'
                      to={`/users/${i.application.user.id}`}
                    >
                      {i.application.user?.lastName}{' '}
                      {i.application.user?.firstName}{' '}
                      {i.application.user?.middleName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {i.application.user?.serial} {i.application.user?.number}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    <Link
                      className='summary-page__link'
                      to={`/applications/${i.application.id}`}
                    >
                      {i.application.id}
                    </Link>
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {i.application?.vehicleType}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {branches?.find((n) => n.id === i.branchId)?.[`name${lang}`]}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {
                      STATUS_TYPES?.find((ii) => ii.value === i.oldStatus)?.label
                    }
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {
                      STATUS_TYPES?.find((ii) => ii.value === i.newStatus)?.label
                    }
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {i.attemptCount}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {i?.application?.user?.phone}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {i.value ? (
                      <CheckIcon
                        sx={{ fontSize: 20 }}
                        className='summary-page__check'
                      />
                    ) : (
                      <CloseIcon
                        sx={{ fontSize: 20 }}
                        className='summary-page__error'
                      />
                    )}
                  </TableCell>

                  <TableCell className='summary-page__td'>
                    {moment(i.createdAt).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell className='summary-page__td'>
                    {moment(i.application.createdAt).format('DD-MM-YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  );
};

export default SummaryPage;
