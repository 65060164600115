import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { categoryStatusesServices } from 'api/services';
import { IResCategoryStatus } from 'api/services/category-statuses/types';
import { ShowNotification } from 'helpers/showNotice';
import Confirmation from 'components/Confirmation/Confirmation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Template from 'components/Template/Template';
import Popup from 'components/Popup/Popup';
import { CreateCategoryStatuses } from 'components/Popup/components/CreateCategoryStatuses/CreateCategoryStatuses';
import { UpdateCategoryStatuses } from 'components/Popup/components/UpdateCategoryStatuses/UpdateCategoryStatuses';
import { statusesName } from 'helpers/statusesName';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import './CategoryStatusesPage.sass';

const CategoryStatusesPage: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { vehicleType } = useParams<{ vehicleType: string }>();
  const [categoryStatuses, setCategoryStatuses] = useState<
    IResCategoryStatus[]
  >([]);
  const [selectedCategoryStatus, setSelectedCategoryStatus] =
    useState<IResCategoryStatus>();
  const [isDeleteStatus, setIsDeleteStatus] = useState(false);
  const [selectedDeleteStatus, setSelectedDeleteStatus] = useState<number>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.CategoryStatuses
  );
  const confirmationRef = useRef<any>();
  const createPopupRef = useRef<any>()
  const updatePopupRef = useRef<any>()

  useEffect(() => {
    if(!categoryId || !vehicleType) return
    getCategoryStatusById();
  }, [categoryId, vehicleType]);

  const getCategoryStatusById = async () => {
    if (!vehicleType) return;
    const params = {
      categoryId: Number(categoryId),
      vehicleType: vehicleType as IVehicleType,
    };
    const res = await categoryStatusesServices
      .getCategoryStatuses(params)
      .then((res) => res.data);
    setCategoryStatuses(res);
  };


  const deleteCategoryStatus = async () => {
    if (!selectedDeleteStatus) return;
    setIsDeleteStatus(true);
    try {
      await categoryStatusesServices.deleteCategoryStatus(selectedDeleteStatus);
      ShowNotification(i18n.statusDeleted, NoticeStatus.INFO);
      getCategoryStatusById();
    } catch {
      ShowNotification(i18n.erorrTryAgain);
    } finally {
      setIsDeleteStatus(false);
      setSelectedDeleteStatus(undefined);
    }
  };

  const cofirmDeleteStatus = (id: number) => {
    confirmationRef.current.onShow();
    setSelectedDeleteStatus(id);
  };

  const openPopup = (data?: IResCategoryStatus) => {
    setSelectedCategoryStatus(data)
    if(data){
      updatePopupRef.current.onShow()
    } else {
      createPopupRef.current.onShow()
    }
  }

  const closePopup = (update?: boolean) => {
    setSelectedCategoryStatus(undefined)
    createPopupRef.current.onHide()
    updatePopupRef.current.onHide()
    if(update) {
      getCategoryStatusById()
    }
  } 

  const closeHandler = () => {
    setSelectedCategoryStatus(undefined)
  }

  return (
    <Template>
      <div className='category-statuses'>
        <Confirmation
          ref={confirmationRef}
          title={i18n.wantToRemove}
          submit={deleteCategoryStatus}
        />
        <div className='category-statuses'>
          <div className='category-statuses__buttons'>
            <Button
              className='category-statuses__create'
              variant='contained'
              size='large'
              color='success'
              onClick={() => openPopup()}
            >
              + {i18n.add}
            </Button>
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>{i18n.status}</TableCell>
                  <TableCell>{i18n.successStatus}</TableCell>
                  <TableCell>{i18n.failureStatus}</TableCell>
                  <TableCell>{i18n.order}</TableCell>
                  <TableCell>{i18n.start}</TableCell>
                  <TableCell>{i18n.finished}</TableCell>
                  <TableCell>{i18n.createdAt}</TableCell>
                  <TableCell>{i18n.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryStatuses
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((i, index) => (
                    <TableRow key={i.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {statusesName(i.status)}
                      </TableCell>
                      <TableCell>
                        {i?.nextSuccessStatus ? statusesName(i?.nextSuccessStatus) : ''}
                      </TableCell>
                      <TableCell>
                        {i?.nextFailureStatus ? statusesName(i?.nextFailureStatus) : ''}
                      </TableCell>
                      <TableCell>{i.order}</TableCell>
                      <TableCell>
                        {i.isInit && (
                          <CheckIcon
                            sx={{ fontSize: 20, color: 'green' }}
                            className='category-statuses__checked-btn'
                            fontSize='large'
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {i.isFinish && (
                          <CheckIcon
                            sx={{ fontSize: 20, color: 'green' }}
                            className='category-statuses__checked-btn'
                            fontSize='large'
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(i.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        <div className='btn-row'>
                          <Button
                            onClick={() => openPopup(i)}
                            className='category-statuses__btn'
                          >
                            <EditIcon
                              sx={{ fontSize: 25, color: '#003956' }}
                            />
                          </Button>
                          <Button
                            onClick={() => cofirmDeleteStatus(i.id)}
                            className='category-statuses__btn'
                            disabled={isDeleteStatus}
                          >
                            <DeleteForeverIcon
                              className='delete-icon'
                              sx={{ fontSize: 25, color: '#ff0000' }}
                            />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
      <Popup ref={createPopupRef} onClick={closeHandler}>
        <CreateCategoryStatuses onHide={closePopup}/>
      </Popup>
      <Popup ref={updatePopupRef} onClick={closeHandler}>
        {selectedCategoryStatus && (
          <UpdateCategoryStatuses data={selectedCategoryStatus} onHide={closePopup}/>
        )}
        </Popup>
    </Template>
  );
};

export default CategoryStatusesPage;
