import { SignIn } from '../pages/Auth/SighIn/SignIn';
import { SignUp } from '../pages/Auth/SignUp/SignUp';
import UsersPage from 'pages//UsersPage/UsersPage';
import UpdateUserPage from 'pages/UpdateUserPage/UpdateUserPage';
import VisitLogsPage from 'pages/VisitLogsPage/VisitLogsPage';
import { CategoriesPage } from 'pages/CategoriestPage/CategoriesPage';
import { CreateCategoryPage } from 'pages/CreateCategoryPage/CreateCategoryPage';
import { UpdateCategoryPage } from 'pages/UpdateCategoryPage/UpdateCategoryPage';
import { CreateDocPage } from 'pages/CreateDocPage/CreateDocPage';
import { DocsPage } from 'pages/DocsPage/DocsPage';
import { UpdateDocPage } from 'pages/UpdateDocPage/UpdateDocPage';
import { ApplicationsPage } from 'pages/ApplicationsPage/ApplicationsPage';
import { CreateApplicationPage } from 'pages/CreateApplicationPage/CreateApplicationPage';
import UserIdentificationPage from 'pages/UserIdentificationPage/UserIdentificationPage';
import { UpdateApplicationPage } from 'pages/UpdateApplicationPage/UpdateApplicationPage';
import PaymentPage from 'pages/PaymentPage/PaymentPage';
import PracticeExercisesPage from 'pages/PracticeExercisesPage/PracticeExercisesPage';
import CreatePracticeExercisesPage from 'pages/CreatePracticeExercisesPage/CreatePracticeExercisesPage';
import UpdatePracticeExercisesPage from 'pages/UpdatePracticeExercisesPage/UpdatePracticeExercisesPage';
import PracticePenaltiesPage from 'pages/PracticePenaltiesPage/PracticePenaltiesPage';
import CreatePracticePenaltyPage from 'pages/CreatePracticePenaltyPage/CreatePracticePenaltyPage';
import UpdatePracticePenaltyPage from 'pages/UpdatePracticePenaltyPage/UpdatePracticePenaltyPage';
import TransactionsPage from 'pages/TransactionsPage/TransactionsPage';
import { CreateApplicationPageByUser } from 'pages/CreateApplicationPageByUser/CreateApplicationPageByUser';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import PassedExam from 'pages/PassedExam/PassedExam';
import { ErrorTypesPage } from 'pages/ErrorTypesPage/ErrorTypesPage';
import { CreateErrorTypePage } from 'pages/CreateErrorTypePage/CreateErrorTypePage';
import { UpdateErrorTypePage } from 'pages/UpdateErrorTypePage/UpdateErrorTypePage';
import { QueuePage } from 'pages/QueuePage/QueuePage';
import { CreateQueuePage } from 'pages/CreateQueuePage/CreateQueuePage';
import { UpdateQueuePage } from 'pages/UpdateQueuePage/UpdateQueuePage';
import { ManagerQueuePage } from 'pages/QueueScreenPage/ManagerQueuePage/ManagerQueuePage';
import { SelectManagerQueuePage } from 'pages/QueueScreenPage/SelectManagerQueuePage/SelectManagerQueuePage';
import { MotordromesPage } from 'pages/MotordromesPage/MotordromesPage';
import { UpdateMotordromePage } from 'pages/UpdateMotordromePage/UpdateMotordromePage';
import { CreateMotordromePage } from 'pages/CreateMotordromePage/CreateMotordromePage';
import ApplicationsForGai from 'pages/ApplicationsForGai/ApplicationsForGai';
import { UpdateClassRoomPage } from 'pages/UpdateClassRoomPage/UpdateClassRoomPage';
import { ClassRoomsPage } from 'pages/ClassRoomsPage/ClassRoomsPage';
import { CreateClassRoomPage } from 'pages/CreateClassRoomPage/CreateClassRoomPage';
import { CarsPage } from 'pages/CarsPage/CarsPage';
import { ExamPage } from 'pages/ExamPage/ExamPage';
import ExamPageById from 'pages/ExamPageById/ExamPageById';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ProtocolPage from 'pages/ProtocolPage/ProtocolPage';
import { SchoolPage } from 'pages/SchoolPage/SchoolPage';
import { CreateSchoolPage } from 'pages/CreateSchoolPage/CreateSchoolPage';
import { UpdateSchoolPage } from 'pages/UpdateSchoolPage/UpdateSchoolPage';
import SummaryPage from 'pages/SummaryPage/SummaryPage';
import { ReportPage } from 'pages/ReportPage/ReportPage';
import { ApplicationHistoryPage } from 'pages/ApplicationHistoryPage/ApplicationHistoryPage';
import AppealPage from 'pages/AppealPage/AppealPage';
import CategoryStatusesPage from 'pages/CategoryStatusesPage/CategoryStatusesPage';
import GeneralReportsPage from 'pages/GeneralReportsPage/GeneralReportsPage';
import ReportsForSchool from 'pages/ReportsForSchool/ReportsForSchool';
import AppealPageById from 'pages/AppealPageById/AppealPageById';
import CertificatesPage from 'pages/CertificatesPage/CertificatesPage';
import BranchesPage from 'pages/BranchesPage/BranchesPage';
import ReportsSchoolForGai from 'pages/ReportsSchoolForGai/ReportsSchoolForGai';
import CreateTicketPage from 'pages/CreateTicketPage/CreateTicketPage';
import TicketsPage from 'pages/TicketsPage/TicketsPage';
import UpdateTicketPage from 'pages/UpdateTicketPage/UpdateTicketPage';
import { ALL_USER_ROLES } from 'constants/roles';
import SegmentIcon from '@mui/icons-material/Segment';
import data from 'store/ducks/i18n/data';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import WebIcon from '@mui/icons-material/Web';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SchoolIcon from '@mui/icons-material/School';
import { SvgIconTypeMap } from '@mui/material';
import AppealsPage from 'pages/AppealsPage/AppealsPage';
import { UserQueuePage } from 'pages/QueueScreenPage/UserQueuePage/UserQueuePage';
import { ReportsPage } from 'pages/ReportsPage/ReportsPage';
import UpdateCertificatePage from 'pages/UpdateCertificatePage/UpdateCertificatePage';
import { VerrifyApplicationsPage } from 'pages/VerrifyApplicationsPage/VerrifyApplicationsPage';
import VerrifyApplicationByIdPage from 'pages/VerrifyApplicationByIdPage/VerrifyApplicationByIdPage';
import VisingPage from 'pages/VisingPage/VisingPage';
import CreateVisingPage from 'pages/CreateVisingPage/CreateVisingPage';
import UpdateVisingPage from 'pages/UpdateVisingPage/UpdateVisingPage';
import OfficialReportsPage from 'pages/OfficialReportsPage/OfficialReportsPage';
import OfficialReportByIdPage from 'pages/OfficialReportByIdPage/OfficialReportByIdPage';
import CreateOfficialReportPage from 'pages/CreateOfficialReportPage/CreateOfficialReportPage';
import CreateOfficialReportTransactionPage from 'pages/CreateOfficialReportTransactionPage/CreateOfficialReportTransactionPage';
import FinesPage from 'pages/FinesPage/FinesPage';
import FinesDetails from 'pages/FinesDetails/FinesDetails';
import CalendarsPage from 'pages/CalendarsPage/CalendarsPage';
import VehicleTypesPage from 'pages/VehicleTypesPage/VehicleTypesPage';

const lang = localStorage.getItem('lang') || 'ru';

const {
  SU,
  RECEPTION,
  SCANNER,
  CASHIER,
  USER,
  MOTORDROME,
  SECURITY,
  QUEUE,
  GAI,
  QUEUE_MANAGER,
  UMCH,
  REPORT,
  HEAD_ACCOUNTANT,
  ACCOUNTANT,
  APPEAL,
  UZAKOVA,
  BRANCH_HEAD,
  ASSISTANT_BRANCH_HEAD,
  SATURDAY_EDITOR,
  HEAD
} = ALL_USER_ROLES;

interface IRoute {
  path: string;
  element: React.ReactNode;
  roles?: IRole[];
  title?: string;
  isHiden?: boolean;
  types?: RouteType[];
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export enum RouteType {
  STATS = 'STATS',
  RECEPTION = 'RECEPTION',
  UMCH = 'UMCH',
  APPEAL = 'APPEAL',
  CASH_REG = 'CASH_REG',
  ACOUNTING = 'ACOUNTING',
  SECURITY = 'SECURITY',
}

const navigation: IRoute[] = [
  {
    path: '/payment',
    element: <PaymentPage />,
    roles: [SU, CASHIER],
    title: 'payment',
    types: [RouteType.CASH_REG],
  },
  {
    path: '/transactions',
    element: <TransactionsPage />,
    roles: [SU, CASHIER, ACCOUNTANT, HEAD_ACCOUNTANT],
    types: [RouteType.CASH_REG, RouteType.ACOUNTING],
    title: 'transactions',
  },
  {
    path: '/transactions/:transactionId/official-report',
    element: <CreateOfficialReportTransactionPage />,
    roles: [SU, CASHIER],
  },
  {
    path: '/applications',
    element: <ApplicationsPage />,
    roles: [
      SU,
      CASHIER,
      RECEPTION,
      SCANNER,
      REPORT,
      UZAKOVA,
      BRANCH_HEAD,
      HEAD,
      ASSISTANT_BRANCH_HEAD,
      ACCOUNTANT,
      HEAD_ACCOUNTANT,
      APPEAL,
      UMCH,
      SATURDAY_EDITOR,
    ],
    types: [
      RouteType.ACOUNTING,
      RouteType.RECEPTION,
      RouteType.UMCH,
      RouteType.APPEAL,
    ],
    title: 'applications',
    icon: WebIcon,
  },
  {
    path: '/applications',
    element: <ApplicationsPage />,
    roles: [USER],
    title: 'applications',
    icon: WebIcon,
  },
  {
    path: '/applications/create',
    element: <CreateApplicationPage />,
    roles: [SU, RECEPTION],
  },
  {
    path: '/applications/create/user',
    element: <CreateApplicationPageByUser />,
    roles: [SU, USER],
  },
  {
    path: '/applications/:applicationId',
    element: <UpdateApplicationPage />,
    roles: [
      SU,
      RECEPTION,
      APPEAL,
      UMCH,
      BRANCH_HEAD,
      ASSISTANT_BRANCH_HEAD,
      USER,
      ACCOUNTANT,
      HEAD_ACCOUNTANT,
      REPORT,
      UZAKOVA,
      SATURDAY_EDITOR,
      HEAD
    ],
  },
  {
    path: '/applications/:applicationId/status-history',
    element: <ApplicationHistoryPage />,
    roles: [
      SU,
      RECEPTION,
      USER,
      REPORT,
      UZAKOVA,
      UMCH,
      APPEAL,
      SATURDAY_EDITOR,
      BRANCH_HEAD,
      ASSISTANT_BRANCH_HEAD,
      HEAD
    ],
  },
  {
    path: '/applications/:applicationId/appeal',
    element: <AppealPage />,
    roles: [SU, APPEAL],
  },
  {
    path: '/applications/:applicationId/ticket',
    element: <CreateTicketPage />,
    roles: [
      SU,
      BRANCH_HEAD,
      ASSISTANT_BRANCH_HEAD,
      HEAD_ACCOUNTANT,
      ACCOUNTANT,
      REPORT,
      UZAKOVA,
      UMCH,
      APPEAL,
      SATURDAY_EDITOR,
    ],
  },
  {
    path: '/applications/:applicationId/official-report',
    element: <CreateOfficialReportPage />,
    roles: [
      SU,
      RECEPTION,
    ],
  },
  {
    path: '/verrify-applications',
    element: <VerrifyApplicationsPage />,
    title: 'verrifyApps',
    icon: WebIcon,
    roles: [SU, BRANCH_HEAD, HEAD, REPORT],
  },
  {
    path: '/verrify-applications/:verrifyAppId',
    element: <VerrifyApplicationByIdPage />,
    roles: [SU, BRANCH_HEAD, HEAD],
  },
  {
    path: '/user-identification',
    title: 'userId',
    roles: [SU, SECURITY],
    types: [RouteType.SECURITY],
    element: <UserIdentificationPage />,
  },
  {
    path: '/visit-logs',
    title: 'visitLogs',
    roles: [SU, SECURITY],
    types: [RouteType.SECURITY],
    element: <VisitLogsPage />,
  },
  {
    path: '/branches',
    element: <BranchesPage />,
    roles: [SU],
  },
  {
    path: '/users',
    title: 'users',
    roles: [SU],
    element: <UsersPage />,
    types: [RouteType.RECEPTION],
  },
  {
    path: '/users/:userId',
    element: <UpdateUserPage />,
    roles: [SU],
  },
  {
    path: '/schools',
    title: 'schools',
    roles: [SU, RECEPTION, UZAKOVA],
    element: <SchoolPage />,
    types: [RouteType.RECEPTION],
  },
  {
    path: '/schools/create',
    element: <CreateSchoolPage />,
    roles: [SU, RECEPTION, UZAKOVA],
  },
  {
    path: '/schools/:schoolId',
    element: <UpdateSchoolPage />,
    roles: [SU, RECEPTION, UZAKOVA],
  },
  {
    path: '/error-types',
    title: 'errors',
    roles: [SU, RECEPTION, UZAKOVA],
    element: <ErrorTypesPage />,
    types: [RouteType.RECEPTION],
  },
  {
    path: '/error-types/create',
    element: <CreateErrorTypePage />,
    roles: [SU, RECEPTION, UZAKOVA],
  },
  {
    path: '/error-types/:errorTypeId',
    element: <UpdateErrorTypePage />,
    roles: [SU, RECEPTION, UZAKOVA],
  },
  {
    path: '/general-reports',
    element: <GeneralReportsPage />,
    roles: [SU, REPORT, UZAKOVA, ASSISTANT_BRANCH_HEAD, BRANCH_HEAD, HEAD],
    title: 'generatReport',
    types: [RouteType.STATS],
  },
  // {
  //   path: '/general-reports/status-history',
  //   element: <GeneralReportsHistoryPage />,
  //   roles: [SU, REPORT, UZAKOVA, ACCOUNTANT, SATURDAY_EDITOR],
  //   title: 'Подробный отчет',
  //   types: [RouteType.STATS],
  // },
  {
    path: '/reports-for-school',
    title: 'schoolStatistics',
    roles: [SU, REPORT, UZAKOVA, HEAD],
    types: [RouteType.STATS],
    element: <ReportsForSchool />,
  },
  {
    path: '/report-school-for-gai',
    title: 'gaiSchoolsReport',
    roles: [SU, REPORT, UZAKOVA, HEAD],
    element: <ReportsSchoolForGai />,
    types: [RouteType.STATS],
  },
  {
    path: '/summary',
    title: 'sumarry',
    roles: [SU, REPORT, UZAKOVA, BRANCH_HEAD, ASSISTANT_BRANCH_HEAD, HEAD],
    element: <SummaryPage />,
    types: [RouteType.STATS],
  },
  {
    path: '/reports',
    title: 'reports',
    roles: [SU, UMCH],
    element: <ReportsPage />,
    types: [RouteType.UMCH],
  },
  {
    path: '/reports/:reportId',
    element: <ReportPage />,
    roles: [SU, UMCH],
  },
  {
    path: '/appeals',
    title: 'appeals',
    roles: [SU, UMCH, APPEAL],
    element: <AppealsPage />,
    types: [RouteType.UMCH, RouteType.APPEAL],
  },
  {
    path: '/appeals/:appealId',
    element: <AppealPageById />,
    roles: [SU, APPEAL, UMCH],
  },
  {
    path: '/passed-exam',
    element: <PassedExam />,
    roles: [SU, ACCOUNTANT],
  },
  {
    path: '/categories',
    title: 'categories',
    element: <CategoriesPage />,
    roles: [SU],
    icon: SegmentIcon,
  },
  {
    path: '/categories/create',
    element: <CreateCategoryPage />,
    roles: [SU],
  },
  {
    path: '/categories/:categoryId',
    element: <UpdateCategoryPage />,
    roles: [SU],
  },
  {
    path: '/categories/:categoryId/vehicle-types/:vehicleType',
    element: <CategoryStatusesPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types',
    element: <VehicleTypesPage />,
    title: 'practiceCategory',
    icon: SegmentIcon,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise',
    element: <PracticeExercisesPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise/create',
    element: <CreatePracticeExercisesPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise/:practiceExercisesId',
    element: <UpdatePracticeExercisesPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise/:practiceExercisesId/practice-penalties',
    element: <PracticePenaltiesPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise/:practiceExercisesId/practice-penalties/create',
    element: <CreatePracticePenaltyPage />,
    roles: [SU],
  },
  {
    path: '/vehicle-types/:vehicleType/practice-exercise/:practiceExercisesId/practice-penalties/:practicePenaltyId',
    element: <UpdatePracticePenaltyPage />,
    roles: [SU],
  },
  {
    path: '/queue',
    title: 'queue',
    roles: [SU, QUEUE, QUEUE_MANAGER],
    element: <QueuePage />,
    icon: AutoAwesomeMotionIcon,
  },
  {
    path: '/queue/create',
    element: <CreateQueuePage />,
    roles: [SU],
  },
  {
    path: '/queue/select',
    title: 'control',
    roles: [SU, QUEUE_MANAGER, QUEUE],
    element: <SelectManagerQueuePage />,
    icon: SportsEsportsIcon,
  },
  {
    path: '/queue/:queueId',
    element: <UpdateQueuePage />,
    roles: [SU, QUEUE],
  },
  {
    path: '/queue-screen/:queueId',
    element: <ManagerQueuePage />,
    roles: [SU, QUEUE_MANAGER],
  },
  {
    path: '/queue-screen/:queueId',
    element: <UserQueuePage />,
    roles: [QUEUE],
  },
  {
    path: '/docs',
    title: 'docs',
    roles: [SU],
    element: <DocsPage />,
    icon: ArticleOutlinedIcon,
  },
  {
    path: '/docs/create',
    element: <CreateDocPage />,
    roles: [SU],
  },
  {
    path: '/docs/:docId',
    element: <UpdateDocPage />,
    roles: [SU],
  },
  {
    path: '/tickets',
    title: 'report',
    roles: [
      SU,
      UMCH,
      REPORT,
      UZAKOVA,
      BRANCH_HEAD,
      ASSISTANT_BRANCH_HEAD,
      APPEAL,
      ACCOUNTANT,
      HEAD_ACCOUNTANT,
      SATURDAY_EDITOR,
    ],
    element: <TicketsPage />,
    icon: SegmentIcon,
  },
  {
    path: '/vising',
    title: 'vising',
    roles: [
      SU,
    ],
    element: <VisingPage />,
    icon: SegmentIcon,
  },
  {
    path: '/vising/create',
    roles: [
      SU,
    ],
    element: <CreateVisingPage />,
  },
  {
    path: '/vising/:visingId',
    roles: [
      SU,
    ],
    element: <UpdateVisingPage />,
  },
  {
    path: '/official-reports',
    title: 'requestForChangeData',
    roles: [
      SU,
      BRANCH_HEAD,
      ASSISTANT_BRANCH_HEAD,
      UZAKOVA,
      REPORT,
      HEAD_ACCOUNTANT,
      HEAD,
      SATURDAY_EDITOR
    ],
    element: <OfficialReportsPage />,
    icon: SegmentIcon,
  },
  {
    path: '/official-reports/:officialReportId',
    roles: [
      SU,
      BRANCH_HEAD,
      UZAKOVA,
      REPORT,
      HEAD_ACCOUNTANT,
      ASSISTANT_BRANCH_HEAD,
      HEAD,
      SATURDAY_EDITOR
    ],
    element: <OfficialReportByIdPage />,
    icon: SegmentIcon,
  },
  {
    path: '/tickets/:ticketId',
    element: <UpdateTicketPage />,
    roles: [
      SU
    ],
  },
  {
    path: '/cabinets',
    title: 'classRooms',
    roles: [SU, QUEUE, QUEUE_MANAGER],
    element: <ClassRoomsPage />,
    icon: SchoolIcon,
  },
  {
    path: `/cabinets/create`,
    element: <CreateClassRoomPage />,
    roles: [SU],
  },
  {
    path: `/cabinets/:classRoomId`,
    element: <UpdateClassRoomPage />,
    roles: [SU],
  },
  {
    path: '/cars',
    title: 'cars',
    roles: [SU],
    element: <CarsPage />,
    icon: DirectionsCarIcon,
  },
  {
    path: '/motordromes',
    title: 'motordromes',
    roles: [SU, MOTORDROME],
    element: <MotordromesPage />,
    icon: DirectionsBusIcon,
  },
  {
    path: '/motordromes/create',
    element: <CreateMotordromePage />,
    roles: [SU, MOTORDROME],
  },
  {
    path: `/motordromes/:motordromeId`,
    element: <UpdateMotordromePage />,
    roles: [SU, MOTORDROME],
  },
  {
    path: `/exam`,
    element: <ExamPage />,
    roles: [SU, MOTORDROME],
  },
  {
    path: '/exam/:examId',
    element: <ExamPageById />,
    roles: [SU, MOTORDROME],
  },
  {
    path: '/certificates',
    title: 'certificates',
    roles: [GAI, SU],
    element: <CertificatesPage />,
    icon: ArticleOutlinedIcon,
  },
  {
    path: '/certificates/:certificateId',
    roles: [SU],
    element: <UpdateCertificatePage />,
  },
  {
    path: '/calendars',
    title: 'calendar',
    roles: [SU, REPORT, UZAKOVA],
    element: <CalendarsPage />,
    icon: DateRangeIcon,
  },
  {
    path: '/protocol',
    element: <ProtocolPage />,
    roles: [SU],
  },
  {
    path: '/applications/gai/:appId',
    element: <ApplicationsForGai />,
    roles: [SU],
  },
  {
    path: '/practice-exercise',
    element: <PracticeExercisesPage />,
    roles: [SU, MOTORDROME],
  },
  {
    path: '/fines',
    element: <FinesPage />,
    roles: [SU, REPORT],
    types: [RouteType.STATS],
    title: 'fines'
  },
  {
    path: '/fines/:userFinesId',
    element: <FinesDetails />,
    roles: [SU, REPORT],
  },
  {
    path: '/auth/sign-in',
    element: <SignIn />,
  },
  {
    path: '/auth/sign-up',
    element: <SignUp />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default navigation;
