import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { reportServices } from 'api/services';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import Template from 'components/Template/Template';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './ReportPage.sass';

interface Props {}

export const ReportPage: React.FC<Props> = ({}) => {
  const [report, setReport] = useState<any>();
  const { reportId } = useParams<{ reportId: string }>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ReportPage
  );

  useEffect(() => {
    if (!reportId) return;
    getReportById();
  }, [reportId]);

  const getReportById = async () => {
    const res = await reportServices
      .getReportById(Number(reportId))
      .then((res) => res.data);
    setReport(res);
  };

  const print = () => {
    window.print();
  };

  return (
    <Template>
      <div className='report-page'>
        <div className='report-page__row'>
          <div className='report-page__date-wrap'>
            <div className='report-page__date'>
              {moment(report?.date).format('DD.MM.YYYY')}
            </div>
            <div className='report-page__date-text'>(сана, вақт)</div>
          </div>
          <div className='report-page__header'>
            <Typography className='report-page__title' variant='h3'>
              Имтиҳон натижалари баённомаси №{report?.id}
            </Typography>
            <Button
              className='report-page__button'
              variant='contained'
              color='success'
              onClick={print}
            >
              Чоп этиш
            </Button>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>№</TableCell>
              <TableCell rowSpan={2}>
                Ҳайдовчиликка номзоднинг фамилияси, исми, отасининг исми
              </TableCell>
              <TableCell rowSpan={2}>Қайси тоифага имтиҳон топширган</TableCell>
              <TableCell rowSpan={2}>
                Ҳайдовчиликка номзод ўқиган ўқув ташкилоти
              </TableCell>
              <TableCell colSpan={2}>Назарий имтиҳон</TableCell>
              <TableCell colSpan={2}>Амалий имтиҳон</TableCell>
              <TableCell rowSpan={2}>Махсус сертификат реквизитлари</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> Нечанчи марта </TableCell>
              <TableCell>баҳоси</TableCell>
              <TableCell> Нечанчи марта </TableCell>
              <TableCell>баҳоси</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report?.statusHistory?.map((i: any, index: number) => (
              <TableRow key={i.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{`${i.application.user.lastName} ${i.application.user.firstName} ${i.application.user.middleName}`}</TableCell>
                <TableCell>{i.application.vehicleType}</TableCell>
                <TableCell>{i.application?.school?.title}</TableCell>
                <TableCell>
                  {(!!i.result.score || i.result.score === 0) && i.attemptCount}
                </TableCell>
                <TableCell>
                  {(!!i.result.score || i.result.score === 0) &&
                    `${i.result.score}%`}
                </TableCell>
                <TableCell>
                  {((!i.value && i.result.details) ||
                    (i.value && i.result.details)) &&
                    i.attemptCount}
                  {!i.result.details && ''}
                </TableCell>
                <TableCell className='points'>
                  {!i.value && i.result.details && i18n.notPassed}
                  {i.value && i.result.details && i18n.passed}
                  {!i.result.details && ''}
                </TableCell>
                <TableCell>{i.application.code.padStart(14, '0')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className='report-page__info-wrap'>
          <div className='report-page__code'>
            <QRCode
              value={window.location.href}
              className='report-page__qr'
              size={70}
            />
          </div>
          <div className='report-page__info'>
            <div className='report-page__wrap'>
              <div className='report-page__text'>Имтиҳон ўтказилган АИТ</div>
              <div className='report-page__line'>
                {report?.branchId === 3 && 'Тошкент имтиҳон маркази'}
                {report?.branchId === 4 && 'Тошкент вилояти имтиҳон маркази'}
                {report?.branchId === 5 && 'ФАРҒОНА ВИЛОЯТИ ИМТИҲОН МАРКАЗИ'}
                {report?.branchId === 6 && 'АНДИЖОН ВИЛОЯТИ ИМТИҲОН МАРКАЗИ'}
                {report?.branchId === 7 && 'Сирдарё Вилояти имтиҳон маркази'}
                {report?.branchId === 8 && 'Жиззах Вилояти имтиҳон маркази'}
                {report?.branchId === 9 && 'Наманган вилояти имтиҳон маркази'}
                {report?.branchId === 10 && 'Қашқадарё вилояти имтиҳон маркази'}
                {report?.branchId === 11 && 'САМАРҚАНД ВИЛОЯТИ ИМТИҲОН МАРКАЗИ'}
              </div>
            </div>
            <div className='report-page__wrap'>
              <div className='report-page__text'>ДЙҲХХ масъул ходими </div>
              <div className='report-page__line'>
                {report?.branchId === 3 && 'Уралов А.'}
                {report?.branchId === 4 && 'Касимов Ш. А.'}
                {report?.branchId === 5 && 'Махсудов Р.Б.'}
                {report?.branchId === 6 && 'ТОЖИБОЕВ Х. Х.'}
                {report?.branchId === 7 && 'Кучмуродов З. Н.'}
                {report?.branchId === 8 && "TOSHPO'LATOV SHOHRUH ISMOIL O'G'LI"}
                {report?.branchId === 9 && "Султонов Саидулуғбек Юнусхонович"}
                {report?.branchId === 10 && "Мардонов  Ойбек  Оромович"}
                {report?.branchId === 11 && "QAYUMOV SARDOR  BAXRILLOYEVICH"}

              </div>
            </div>
            <div className='report-page__wrap'>
              <div className='report-page__text'>АИТ видеокузатувчиси</div>
              <div className='report-page__line'>
                {report?.branchId === 3 && 'Зарипов Б.Ш.'}
                {report?.branchId === 4 && 'Акрамов Э.Д.'}
                {report?.branchId === 5 && 'Садиков Р.Ж.'}
                {report?.branchId === 6 && 'ХАЙДАРОВ У. А.'}
                {report?.branchId === 7 && 'Тохтамуродов Ф. Д.'}
                {report?.branchId === 8 && "RASHIDOV BEKZODJON SUNATULLA O'G'LI"}
                {report?.branchId === 9 && "Ким Илья Владимирович"}
                {report?.branchId === 10 && "Avlaev  Sardor  Shermatovich"}
                {report?.branchId === 11 && "ABDULLAYEV  DAMIR  SAMATOVICH"}
              </div>
            </div>
            <div className='report-page__wrap'>
              <div className='report-page__text'>АИТ оператори </div>
              <div className='report-page__line'>
                {report?.branchId === 3 && 'Шоносиров О.Ш.'}
                {report?.branchId === 4 && 'Шонасиров О.'}
                {report?.branchId === 5 && 'ДЕХКАНБАЕВ Н. И.'}
                {report?.branchId === 6 && 'ҲОЛИКБЕРДИЕВ Ж. З.'}
                {report?.branchId === 7 && 'Назаров И. Б.'}
                {report?.branchId === 8 && 'ORZIQULOV SHAHZOD SHUXRATOVICH'}
                {report?.branchId === 9 && 'Маматҳонов Нурбек Эркин Ўғли'}
                {report?.branchId === 10 && 'Ислом  Азим  ўғли'}
                {report?.branchId === 11 && "KHUSENOV  FARRUKH  ZOXIROVICH"}
              </div>
            </div>
            <div className='report-page__wrap'>
              <div className='report-page__text'>
                Автоматлаштирилган автодром диспетчери
              </div>
              <div className='report-page__line'>
                {report?.branchId === 3 && 'Хамидуллаев А.А.'}
                {report?.branchId === 4 && 'Шахарибов С.'}
                {report?.branchId === 5 && 'ДЕХҚОНОВ Қ. М.'}
                {report?.branchId === 6 && 'СОЛОМОВ Д. Г.'}
                {report?.branchId === 7 && 'Ташбулов Зафар Каримжанович'}
                {report?.branchId === 8 && 'ANVAROV DILSHOD ABDURAYIMOVICH'}
                {report?.branchId === 9 && 'Собиржонов Жамшидбек Улуғбек ўғли'}
                {report?.branchId === 10 && 'Ядгаров  Сарвар  Исматиллаевич'}
                {report?.branchId === 11 && "SAYDAXMADOV  FAZLIDDIN   SADRIDDIN   O’G’LI"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};
