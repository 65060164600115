import { IAdditionalUserRoles, IResUser } from "api/services/users/types"
import cn from 'classnames'
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/store";
import * as userActions from 'store/ducks/users/actions';
import * as serviceActions from 'store/ducks/services/actions';
import './DropDown.sass'
import { useEffect } from "react";

interface Props {
  data: IResUser
  className?: string
}

const DropDown: React.FC<Props> = ({
  data,
  className = '',
}) => {
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const { user } = useSelector((state: AppState) => state.users)
  const dispatch = useDispatch();

  useEffect(() => {
    getServices()
  }, [])


  const changeUser = (data: IAdditionalUserRoles) => {
    if(!user) return
    dispatch(userActions.setCurrentUser({
      ...user,
      additionalUserRoles: user.additionalUserRoles.some((i: any) => i?.branchId === user?.branchId && i?.role === user?.role) ?  [...user.additionalUserRoles] : [...user.additionalUserRoles, {role: user?.role, branchId: user?.branchId, userId: user?.ud }],
      branchId: data?.branchId,
      role: data?.role
    }));
    localStorage.setItem('user', JSON.stringify({
      ...user,
      additionalUserRoles: user.additionalUserRoles.some((i: any) => i?.branchId === user?.branchId && i?.role === user?.role) ?  [...user.additionalUserRoles] : [...user.additionalUserRoles, {role: user?.role, branchId: user?.branchId, userId: user?.ud }],
      branchId: data?.branchId,
      role: data?.role
    }))
    window.location.reload()
  }

  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  return (
    <div className={cn ('dropdown', {
      [className]: className
    })}>
      <div className="dropdown__label">{data.role} {branches.find((i) => i.id === data.branchId)?.nameRu}</div>
      <div className="dropdown__list">
        {!!data?.additionalUserRoles?.length && data.additionalUserRoles.map((i, index) => (
          <div onClick={() => changeUser(i)} className="dropdown__item" key={index}>{i?.role} {branches?.find((ii) => ii?.id === i?.branchId)?.nameRu || ''} </div>
        ))}
      </div>
    </div>
  )
}

export default DropDown